import React, { useState } from "react";
import "./App.css";
import Header from "./components/Header/Header";
import Welcoming_screen  from "./Pages/Welcoming_screen ";
import Ourprojects from "./Pages/Ourprojects";
import Invesment from "./Pages/Invesment-project";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Overviwe from "./Pages/Overviwe";
import AddContract from "./Pages/AddContract";
import { useEffect } from "react";
import Addproject from "./Pages/Addproject";
import Newfeature from "./Pages/Newfeature";
import Projectfeatures from "./Pages/Projectfeatures";
import axios from "axios";
import Ourproject from "./Pages/Ourproject";
import Updateproject from "./Pages/Updateproject";
import Modal from "react-modal";
import Updatafeature from "./Pages/Updatafeature";
import Affilientfeature from "./Pages/Affilientfeature";
import UpdateAffilientfeature from "./Pages/UpdateAffilientfeature";
import ProjectOverViwe from "./Pages/ProjectOverViwe";
import { useDispatch } from "react-redux";
import { fetchProjects } from "./stores/projectsSlice";
Modal.setAppElement("#root");

function App() {
  const [showChildComponent, setShowChildComponent] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);

  const [loading, setLoading] = useState(false);
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("darkMode") === "true"
  );
  const [language, setlanguage] = useState(
    localStorage.getItem("main") === "true"
  );
  const [contents, setcontents] = useState("englishLanguage");

  const [ar_name, setArabicFeatureName] = useState("");
  const [en_name, setEnglishFeatureName] = useState("");
  const [ar_description, setArabicDescription] = useState("");
  const [en_description, setEnglishDescription] = useState("");
  const [images, setImages] = useState([]);
  const images_ids = React.useRef([]);
  const [attributes, setAttributies] = useState([
    {
      id: 0,
      name: "",
      type: "",
      isRequired: "",
      ar_description: "",
      en_description: "",
      children: [],
    },
  ]);

  const [sub_features, setSubFeatures] = useState([
    {
      id: 1,
      ar_name: "",
      en_name: "",
      ar_description: "",
      en_description: "",
      ar_note: "",
      en_note: "",
      api: {
        endpoint: "",
        type: "POST",
        response: "",
        error: "",
        payloads: [
          {
            id: 1,
            name: "",
            payload_value: "",
            isRequired: true,
          },
        ],
      },
      tables: [
        {
          id: 1,
          title: "",
          description: "",
          columns_number: 1,
          columns_titles: ["title1"],
          rows: [{ id: 1, title1: "" }],
        },
      ],
    },
  ]);
  const [children, setChildren] = useState([
    {
      id: 1,
      name: "",
      type: "",
      isRequired: "",
      ar_description: "",
      en_description: "",
      children: [],
    },
  ]);

  // get all projects
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProjects());
  }, [dispatch]);

  const handleFileUpload = async (projectId) => {
    setLoading(true);

    for (let i = 0; i < images.length; i++) {
      const fromData = new FormData();
      fromData.append(`file`, images[i]);

      const config_file = {
        method: "post",
        url: "https://etifaq.app/api/files",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "multipart/form-data",
          "content-length": `${images[i].size}`,
          "Access-Control-Allow-Origin": "*" ,
          Authorization: "Bearer 1734|WZFIBiz63fnnhWryRxse1iE7fGKlG4kcjGOtUrzg",
        },
        data: fromData,
      };

      try {
        const response = await axios.request(config_file);
        images_ids.current.push(response.data.data.id);
      } catch (error) {
        console.log(error);
      }
    }

    var config = {
      method: "post",
      url: `https://etifaq.app/api/projects/${projectId}/features`,
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin ": "*" ,
        Authorization: "Bearer 1734|WZFIBiz63fnnhWryRxse1iE7fGKlG4kcjGOtUrzg",
        "X-CSRF-TOKEN": "",
      },
      data: finalObject,
    };

    try {
      const response = await axios.request(config);
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
    window.location.pathname = "/Ourprojects";

    setLoading(false);
  };

  //update feauter

  const handleFileUpdate = async (projectId, featureId) => {
    setLoading(true);

    for (let i = 0; i < images.length; i++) {
      const fromData = new FormData();
      fromData.append(`file`, images[i]);

      const config_file = {
        method: "post",
        url: "https://etifaq.app/api/files",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Access-Control-Allow-Origin ": "*" ,
          "Content-Type": "multipart/form-data",
          "content-length": `${images[i].size}`,
          Authorization: "Bearer 1734|WZFIBiz63fnnhWryRxse1iE7fGKlG4kcjGOtUrzg",
        },
        data: fromData,
      };

      try {
        const response = await axios.request(config_file);
        images_ids.current.push(response.data.data.id);
      } catch (error) {
        console.log(error);
      }
    }

    var config = {
      method: "Put",
      url: `https://etifaq.app/api/projects/${projectId}/features/${featureId}`,
      headers: {
        "X-Requested-With": "XMLHttpRequest",
          "Access-Control-Allow-Origin ": "*" ,
        "Content-Type": "application/json",
        Authorization: "Bearer 1734|WZFIBiz63fnnhWryRxse1iE7fGKlG4kcjGOtUrzg",
        "X-CSRF-TOKEN": "",
      },
      data: finalObject,
    };

    try {
      const response = await axios.request(config);
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
    window.location.pathname = `/Ourprojects`;
  };

  const finalObject = {
    ar_name,
    en_name,
    ar_description,
    en_description,
    images_ids: images_ids.current,
    attributes,
    sub_features,
  };

  useEffect(() => {
    localStorage.setItem("main", language);
    localStorage.setItem("darkMode", darkMode);
    if (language) {
      setcontents("englishLanguage");
    } else {
      setcontents("arabicLanguage");
    }
  }, [language, darkMode]);

  const test = (index) => {
    let newData = [...attributes];
    newData[index].children = children;
    setAttributies(newData);
  };

  const [activeData, setActiveData] = useState({});

  return (
    <div
      className={language ? "main" : "arabic"}
      style={
        darkMode === "light-mode"
          ? { backgroundColor: "#003b57" }
          : { backgroundColor: "#0E4F6E" }
      }
    >
      <div className={darkMode ? "dark-mode" : "light-mode"}>
        <div className="d-flex justify-content-end">
          <Header
            darkMode={darkMode}
            setDarkMode={setDarkMode}
            language={language}
            setlanguage={setlanguage}
          />
        </div>
        <BrowserRouter>
          <Routes>
            <Route path="" element={<Welcoming_screen  contents={contents} />} />
            <Route
              path="/Ourprojects"
              element={
                <Ourprojects
                  contents={contents}
                  showChildComponent={showChildComponent}
                  setShowChildComponent={setShowChildComponent}
                  selectedProjectId={selectedProjectId}
                  setSelectedProjectId={setSelectedProjectId}
                  language={language}
                />
              }
            ></Route>
            <Route
              path="/Ourproject"
              element={<Ourproject contents={contents} language={language} />}
            ></Route>

            <Route
              path="/Ourprojects/Addproject"
              element={
                <Addproject
                  darkMode={darkMode}
                  loading={loading}
                  setLoading={setLoading}
                />
              }
            ></Route>
            <Route
              path="/Ourprojects/:projectId"
              element={
                <Updateproject
                  darkMode={darkMode}
                  loading={loading}
                  setLoading={setLoading}
                />
              }
            ></Route>
            <Route
              path="/Ourprojects/Updateproject/Updatafeature/:projectId/:featureId"
              element={
                <Updatafeature
                  darkMode={darkMode}
                  attributes={attributes}
                  setAttributies={setAttributies}
                  ar_name={ar_name}
                  setArabicFeatureName={setArabicFeatureName}
                  en_name={en_name}
                  setEnglishFeatureName={setEnglishFeatureName}
                  ar_description={ar_description}
                  setArabicDescription={setArabicDescription}
                  en_description={en_description}
                  setEnglishDescription={setEnglishDescription}
                  setImages={setImages}
                  test={test}
                  children={children}
                  setChildren={setChildren}
                  loading={loading}
                  setSubFeatures={setSubFeatures}
                  images_ids={images_ids}
                />
              }
            ></Route>

            <Route
              path="/Ourprojects/Updateproject/Updatafeature/UpdateAffilientfeature/:projectId/:featureId"
              element={
                <UpdateAffilientfeature
                  darkMode={darkMode}
                  sub_features={sub_features}
                  setSubFeatures={setSubFeatures}
                  handleFileUpdate={handleFileUpdate}
                  loading={loading}
                />
              }
            ></Route>

            <Route
              path="/Ourprojects/Addproject/Addfeature/newfeature/:projectId"
              element={
                <Newfeature
                  darkMode={darkMode}
                  attributes={attributes}
                  setAttributies={setAttributies}
                  ar_name={ar_name}
                  setArabicFeatureName={setArabicFeatureName}
                  en_name={en_name}
                  setEnglishFeatureName={setEnglishFeatureName}
                  ar_description={ar_description}
                  setArabicDescription={setArabicDescription}
                  en_description={en_description}
                  setEnglishDescription={setEnglishDescription}
                  setImages={setImages}
                  test={test}
                  children={children}
                  setChildren={setChildren}
                  loading={loading}
                  images={images}
                />
              }
            ></Route>

            <Route
              path="/Ourprojects/Addproject/Addfeature/newfeature/Affilientfeature/:projectId"
              element={
                <Affilientfeature
                  handleFileUpload={handleFileUpload}
                  darkMode={darkMode}
                  sub_features={sub_features}
                  setSubFeatures={setSubFeatures}
                  loading={loading}
                />
              }
            ></Route>

            <Route
              path="/Ourprojects/Addproject/Addfeature/newfeature/Projectfeatures/:projectId"
              element={
                <Projectfeatures
                  loading={loading}
                  setLoading={setLoading}
                  language={language}
                  darkMode={darkMode}
                  showChildComponent={showChildComponent}
                  setShowChildComponent={setShowChildComponent}
                />
              }
            ></Route>

            <Route
              path="/Ourprojects/Invesment/:projectId"
              element={
                <ProjectOverViwe
                  darkMode={darkMode}
                  language={language}
                  contents={contents}
                  setActiveData={setActiveData}
                />
              }
            >
              <Route
                path="/Ourprojects/Invesment/:projectId"
                element={<Invesment language={language} contents={contents} />}
              />
              <Route
                path="/Ourprojects/Invesment/:projectId/Overviwe/:featureId"
                element={
                  <Overviwe
                    darkMode={darkMode}
                    language={language}
                    contents={contents}
                    activeData={activeData}
                  />
                }
              />
              <Route
                path="/Ourprojects/Invesment/:projectId/Contract/:featureId/:subFeatureId"
                element={
                  <AddContract
                    darkMode={darkMode}
                    language={language}
                    contents={contents}
                    activeData={activeData}
                  />
                }
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}
export default App;
